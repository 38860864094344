
export const Why = () => {
    return (<>
        <section className="section why scroll-snap-section snap-auto">
            <div className="whyBunnyContainer">
                <div className="titleContainer">
                    <h3>? Why Bunny</h3>

                </div>
                <div className="cardContainer">
                    <div className="ansContainer">
                        <img src="/images/BitBunny55.png" alt="" style={{ "--d": 1 }} />
                        <div className="textContainer">
                            <h4>Diversity in the MEME Space</h4>
                            <p>Differentiating itself from Dogecoin's male-centric image, Bunny introduces a cute, rebellious character, appealing to a broader audience, especially women.</p>
                        </div>
                    </div>
                    <div className="ansContainer">
                        <img src="/images/BitBunny223.png" alt="" style={{ "--d": 2 }} />
                        <div className="textContainer">
                            <h4>Aligning with the Chinese Zodiac</h4>
                            <p>The Bunny brand resonates globally, particularly with the younger demographic, establishing a solid foundation for broad-based consensus.</p>
                        </div>
                    </div>
                    <div className="ansContainer">
                        <img src="/images/BitBunny610.png" alt="" style={{ "--d": 3 }} />
                        <div className="textContainer">
                            <h4>Leading the DRC20 MEME Ecosystem</h4>
                            <p>Bunny stands as the premier MEME community in the DRC20 ecosystem and is poised to become a prominent memecoin in the future with its onmichian inscription.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section why scroll-snap-section snap-auto">

            <div className="whyDRC20Container">
                <div className="titleContainer">
                    <h3>? Why DRC20</h3>
                </div>
                <div className="cardContainer">
                    <div className="ansContainer">
                        <img src="/images/BitBunny11.png" alt="" style={{ "--d": 1 }} />
                        <div className="textContainer">
                            <h4>Cardinals Protocol Enlarge Inscription Function</h4>
                            <p>The Cardinals protocol surpasses Ordinals in functionality and security, solving issues like inscription loss during UTXO transfers and offering smoother decentralized swaps and indexing.</p>
                        </div>
                    </div>
                    <div className="ansContainer">
                        <img src="/images/BitBunny624.png" alt="" style={{ "--d": 2 }} />
                        <div className="textContainer">
                            <h4>Dogecoin's MEME Dominance</h4>
                            <p>Leveraging Dogecoin's inherent MEME appeal, DRC20 becomes an ideal platform for Bunny's journey.</p>
                        </div>
                    </div>
                    <div className="ansContainer">
                        <img src="/images/BitBunny777.png" alt="" style={{ "--d": 3 }} />
                        <div className="textContainer">
                            <h4>DRC20 Ecosystem Potential</h4>
                            <p>Utilizing the Cardinals protocol, Bunny enables extensive collaboration across the DRC20 ecosystem and promotes its culture through AI-powered, customized NFT creation within its community.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

