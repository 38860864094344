import { FaArrowUp, FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

export const Army = () => {
    const scrollToTop = () => {
        const scrollContainer = document.querySelector('.scroll-snap-container');
        scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <section className="section army scroll-snap-section snap-center">
            <div className="textContainer">
                <h3>Bunny Army</h3>
                <p>Are you prepared for this "crazy" digital age? Are you "crazy" enough? Whether you are young and eager to seize your era or yearning for the wildness of youth, join in the madness with Crazy Bunny. Let's run with the world's cutting-edge rhythm, as unrestrained as rabbits!</p>
            </div>
            <div className="buttonContainer">
                <a href="https://bunnydoll.bitcast.space/" className="socialMedia" target="_BLANK" rel="noreferrer" style={{ "--hover-c": "rgba(169, 72, 169, 0.3)" }}>
                    ❤️‍🔥 🐰 Talk to BunnyDoll now 🐰 ❤️‍🔥
                </a>
            </div>
            <div className="socialMediaContainer">
                <a href="https://t.me/Bunny_drc20" className="socialMedia" style={{ "--c": "#0088cc" }}>
                    <FaTelegramPlane />
                </a>
                <a href="https://discord.gg/s5hQtCfa5w" className="socialMedia" style={{ "--c": "#7289da" }}>
                    <FaDiscord />
                </a>
                <a href="https://twitter.com/bunny_drc20" className="socialMedia" style={{ "--c": "#ffffff" }}>
                    <FaXTwitter />
                </a>
            </div>

            <div onClick={scrollToTop} className="scrollToTop">
                <FaArrowUp />
                <p>Back to Top</p>
            </div>
        </section>
    )
}