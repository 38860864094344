import gsap from "gsap"
import { ScrollTrigger } from "gsap/all"
import { useEffect, useRef, useState } from "react"

export const Counter = () => {
    gsap.registerPlugin(ScrollTrigger)

    const supplyCount = { value: 0 };
    const [supply, setSupply] = useState(0);
    const totalSupply = 2.1;
    const supplyRef = useRef(null)

    const mintingCount = { value: 0 };
    const [minting, setMinting] = useState(0);
    const totalMinting = 100;
    const mintingRef = useRef(null)


    const goalCount = { value: 0 };
    const [goal, setGoal] = useState(0);
    const totalGoal = 3;
    const goalRef = useRef(null)

    useEffect(() => {
        const scroller = document.querySelector(".scroll-snap-container");
        gsap.to(supplyCount, {
            value: totalSupply,
            duration: 2,
            onUpdate: () => setSupply(((supplyCount.value).toFixed(1))),
            scrollTrigger: {
                trigger: supplyRef.current,
                scroller: scroller,
                toggleActions: "restart none restart restart",
            }
        });
        gsap.to(mintingCount, {
            value: totalMinting,
            duration: 3,
            onUpdate: () => setMinting(Math.floor((mintingCount.value).toFixed(1))),
            scrollTrigger: {
                trigger: mintingRef.current,
                scroller: scroller,
                toggleActions: "restart none restart restart",
            }
        });
        gsap.to(goalCount, {
            value: totalGoal,
            duration: 1,
            onUpdate: () => setGoal(Math.floor((goalCount.value).toFixed(1))),
            scrollTrigger: {
                trigger: goalRef.current,
                scroller: scroller,
                toggleActions: "restart none restart restart",
            }
        });

    }, [])

    return (
        <section className="section counter scroll-snap-section snap-center">
            <div className="textContainer">
                <p className="countNum" ref={supplyRef}>{supply}<span>Trillion</span></p>
                <p>TOTAL SUPPLY <br /> <br /></p>
            </div>
            <div className="textContainer">
                <p className="countNum" ref={mintingRef}>{minting}<span>Million</span></p>
                <p>Tokens/ mint, <br />fostering inclusivity</p>
            </div>
            <div className="textContainer">
                <p className="countNum" ref={goalRef}><span>Top</span>{goal}</p>
                <p>DRC20 inscriptions, <br />community-driven value</p>
            </div>

        </section>
    )
}