import "../styles/bg.css"


const Background = () => {

    const bg3 = <div className="videoBg">
        <video
            autoPlay={true} 
            loop={true}
            controls={false} 
            playsInline
            muted 
            type="video/mp4"
            
            src="/images/bg3.mp4"></video>
    </div>


    return bg3;
}

export default Background;