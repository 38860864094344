import { Hero } from "../sections/landing/Hero"
import { About } from "../sections/landing/About"
import { Why } from "../sections/landing/Why"
import { Counter } from "../sections/landing/Counter"
import { VisionMision } from "../sections/landing/VisionMision"
import { Roadmap } from "../sections/landing/Roadmap"
import { HowToBuy } from "../sections/landing/HowToBuy"
import { Army } from "../sections/landing/Army"
import Background from "../components/Background"



export const Landing = () => {
    return (
        <div className="scroll-snap-container">
           <Background />
            <Hero />
            <About />
            <Why />
            <Counter />
            <VisionMision />
            <Roadmap />
            <HowToBuy />
            <Army />
        </div>
    )
}