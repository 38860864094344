

export const HowToBuy = () => {

    return (
        <section className="section howToBuy scroll-snap-section">
            <div className="videoContainer">
                <video controls={true} type="video/mp4" src="/images/how-to-buy-bunny.mp4"></video>
            </div>
            <div className="linkContainer">
                <a style={{ "--hover-c": "rgba(255, 119, 17, 0.3)" }} target="_BLANK" href="https://unielon.com/swap" rel="noreferrer">Buy now with <img src="/images/uni-elon.svg" alt="UniElon" /></a>
                <a style={{ "--hover-c": "rgba(117, 80, 255, 0.3)" }} target="_BLANK" href="https://www.coinw.com/frontSpot/h5/trading?symbol=1608" rel="noreferrer">Buy now with <img src="/images/coin-w.svg" alt="CoinW" /></a>
            </div>
            <div className="textContainer">
                <p>BTC Metaverse <br />
                    <p className="textJusify">
                        Bunny will release a series of NFT characters using the Ordinals protocol and BRC420 protocol, co-building a Bitmap-based BTC metaverse ecosystem.
                    </p>
                </p>
            </div>
            <div className="comingSoonContainer">
                <a style={{ "--hover-c": "rgba(75, 182, 210, 0.3)" }} target="_SELF" href="." rel="noreferrer"><img src="/images/brc20.ico" alt="BRC20" />(Coming Soon)</a>
            </div>
        </section>
    )
}