import { HiMiniRocketLaunch } from "react-icons/hi2";
import { GiExpander } from "react-icons/gi";
import { GiStumpRegrowth } from "react-icons/gi";
import { GiHumanPyramid } from "react-icons/gi";

export const Roadmap = () => {

    return (
        <section className="section roadmap scroll-snap-section">
              <div className="titleContainer">
                <h3>ROADMAP</h3>
            </div>
            <div className="flexContainer">

            <div className="container">
                <HiMiniRocketLaunch className="icon"/>
                <h3>Launch</h3>
                <p>5,000 Holders<br />
                    Social Media Launch<br />
                    1,000 Telegram Bunnies member
                </p>
            </div>
            <div className="container">
                <GiExpander className="icon"/>
                <h3>Expansion</h3>
                <p>10,000 Holders<br />
                    Website Launch<br />
                    KOL Partnerships<br />
                    3,000 Telegram Bunnies member
                </p>
            </div>
            <div className="container">
                <GiStumpRegrowth className="icon"/>
                <h3>Growth</h3>
                <p>50,000 Holders<br />
                    Crypto Press Releases<br />
                    10,000 Telegram Bunnies member
                </p>
            </div>
            <div className="container">
                <GiHumanPyramid className="icon"/>
                <h3>Scale
                </h3>
                <p>100,000 Holders<br />
                    Top 10 CEX Listings<br />
                    Top 3 Coin on DRC-20<br />
                    Over $100M Market Cap<br />
                    50,000 Telegram Bunnies member
                </p>
            </div>
            </div>
        </section>
    )
}