import { FaArrowDown } from "react-icons/fa";



export const Hero = () => {
    // const title1 = useRef(null)
    // const title2 = useRef(null)
    // const scrollDown = useRef(null)
    // gsap.registerPlugin(ScrollTrigger);
    // useEffect(() => {
    //     gsap.fromTo(title1.current, {
    //         scale: 0,
    //         x: 400,
    //         duration: 1
    //     }, {
    //         scale: 1,
    //         x: 300,
    //         y: -120
    //     });
    //     gsap.fromTo(title2.current, {
    //         scale: 0,
    //         x: -400,
    //         duration: 1
    //     }, {
    //         scale: 1,
    //         x: -300,
    //         y: 120
    //     });
    // }, [])

    
    const scrollToNext = () => {
        const scrollContainer = document.querySelector('.scroll-snap-container');
        scrollContainer.scrollBy({
            top: window.innerHeight, 
            left: 0,
            behavior: 'smooth' 
        });
    };
    return (
        <section className="section hero scroll-snap-section snap-center strict-no-padding">
            <h1>Bunny<br/>DRC20</h1>
            <p>The Best Memecoin on Dogechain</p>
            <div onClick={scrollToNext} className="scrollDown">
                <p>scroll</p>
                <FaArrowDown />
            </div>
        </section>
    )
}