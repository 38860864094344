export const VisionMision = () => {
    return (
        <section className="section vision-mision scroll-snap-section snap-center">
            <div className="card">
                <div className="textContainer">
                    <h3>Vision</h3>
                        <p>Establish a truly community-owned MEME culture community.</p>
                        <p><b>B</b> - Building</p>
                        <p><b>U</b> - Unanimous</p>
                        <p><b>N</b> - Nice</p>
                        <p><b>N</b> - New</p>
                        <p><b>Y</b> - Young</p>

                </div>
                <div className="imgContainer">
                </div>
                <div className="textContainer">
                    <h3>Mision</h3>
                    <p>To provide a decentralized ecosystem that inspires and supports the next generation's desire for growth, creativity, and freedom, championing a community driven by bold ideas and the power to redefine the world.</p>
                </div>
            </div>
        </section>
    )
}