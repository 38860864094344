export const About = () => {
    return (
        <section className="section about scroll-snap-section">
            <div className="imgContainer">
                <img className="upDownAnimation" src="/images/b-bunny.png" alt="" />
            </div>
            <div className="textContainer whatIsBunny">
                <h3>What is Bunny</h3>
                <p>Bunny, a meme culture inscription belonging to Generation Z, embodies the emerging innovation and passionate youthfulness of the Web3 industry. Unlike the tameness of 'Rabbit' and the wildness of 'Hare', 'Bunny' symbolizes a playful yet mischievous spirit, infused with innovative ideas and youthful zest.</p>
            </div>
            <div className="textContainer revolutionizing">
                <h3>DRC20: Revolutionizing Dogecoin Ecosystem</h3>
                <p>DRC20, developed in response to the constraints of BRC20, ushers in a fresh era of digital assets within the Doge Chain. This innovation is marked by enhanced safety, speed, cost efficiency, and the flexibility to incorporate various content into inscriptions, revolutionizing the approach to digital tokens.</p>
            </div>
        </section>
    )
}