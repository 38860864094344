import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/main.css"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD5m-VJeaFNxiWeSCQmV6F3DscXJzPXmIM",
  authDomain: "bunny-drc20.firebaseapp.com",
  projectId: "bunny-drc20",
  storageBucket: "bunny-drc20.appspot.com",
  messagingSenderId: "388606279158",
  appId: "1:388606279158:web:cfac6d30a38f3fe1ec5bd7",
  measurementId: "G-3DTJ9HKZ5T"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);
